import * as Sentry from '@sentry/react';
import { selectDeviceType } from 'features/application/applicationSlice';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { remoteVideoRatioChanged } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { selectTileWidth } from 'features/layout/features/dimensions/dimensionsSlice';
import {
  selectHistoricalSpeakers,
  selectOrderedStreams,
} from 'features/layout/features/order/orderSlice';
import { remoteFeedDisconnected, remoteFeedUpdated } from 'features/streaming/streamingSlice';
import {
  FeedId,
  PublisherMid,
  RemoteFeedStream,
  RemoteFeedUIState,
  RemoteStreamsByFeedId,
  SubscriberMid,
  SubscribeStreamList,
} from 'features/streaming/types';
import { selectActiveMediaDevice } from 'features/user-media/userMediaSlice';
import { UserId } from 'features/users/types';
import Janus, { JanusJS } from 'lib/janus';
import invert from 'lodash.invert';
import { MutableRefObject } from 'react';
import { store } from 'store/store';
import { logger } from 'utils/logger';
import {
  AudioElement,
  ConfigureSimulcastTemplate,
  E2EEQueueUpdateOptions,
  E2EEReceivingQueue,
  FeedStreamInfo,
  JanusConnection,
  ReceivingFeedStreamSendingState,
  ReceivingStreamsStats,
  RTCClient,
  StreamSubscriptionState,
} from 'utils/webrtc';
import { AudioPool, UnlockedAudio } from 'utils/webrtc/AudioPool';
import { MediaStreamStats } from 'utils/webrtc/MediaStreamStats';
import { MediaList } from 'utils/webrtc/receiving/MediaList';
import { StreamingReceiver } from 'utils/webrtc/receiving/StreamingReceiver';
import { VideoroomReceiver } from 'utils/webrtc/receiving/VideoroomReceiver';
import { v4 as uuidv4 } from 'uuid';
import throttle from 'lodash.throttle';

import { ControlledReceivingHandle, VideoroomConnectionState } from './ControlledReceivingHandle';
import { sendMessage, updateSimulcastTemplate } from './messages';
import { StreamKind } from './types';

// kicks off subscription flow which is implemented in `receivers`
// manages media that `receivers` yield

export class ReceivingFeed {
  handle: string = uuidv4();

  feedIdByMid: Record<SubscriberMid, FeedId> = {};

  updateTimer: number = 0;

  streamsStats: ReceivingStreamsStats = {};

  audioElements: Record<SubscriberMid, AudioElement> = {};

  receiver: VideoroomReceiver | StreamingReceiver | null = null;

  media: MediaList = new MediaList();

  initReceiver = (mode: 'streaming' | 'videoroom') => {
    this.receiver =
      mode === 'streaming' ? new StreamingReceiver(this) : new VideoroomReceiver(this);
  };

  e2eeQueue: E2EEReceivingQueue = {};

  private setAudioElementOutput = async (
    element: AudioElement,
    audioOutputId: string | undefined
  ) => {
    if (audioOutputId && element?.setSinkId) {
      await element.setSinkId(audioOutputId);
    }
  };

  attachAudio = async (mid: SubscriberMid) => {
    const feedId = this.feedIdByMid[mid];
    const stream = this.media.subscriberStream(feedId, mid);

    if (!(feedId && stream)) {
      logger
        .remote({ system: true, capture: 'streaming' })
        .warn(
          'Got an anomalous behaviour while attaching a media element. No feed or an active stream?'
        );

      return;
    }

    let element = this.audioElements[mid];

    // handle unlocked audio only once
    if (!element) {
      this.audioElements[mid] = await AudioPool.obtainElement();
      element = this.audioElements[mid];
    }

    if (!element) {
      return;
    }

    logger
      .remote({ system: true, tier: 1 })
      .info(`Adding media to UI, feed=${feedId}, mid=${mid}, type=audio`);

    try {
      element.srcObject = stream.mediaStream;
      try {
        const audioOutputId = selectActiveMediaDevice(store.getState(), 'audiooutput');
        await this.setAudioElementOutput(element, audioOutputId);
      } catch (error) {
        logger.remote().error('An error occurred while attaching the audio output', error);
        Sentry.captureException(error);
      }

      await element.play();
    } catch (error) {
      Sentry.captureException(error);
      logger.remote().error('An error occurred while attaching an audio element', error);
    }

    const feed = this.receiver?.plugin;

    if (feed) {
      this.streamsStats[mid] = new MediaStreamStats({ feed, feedId, mid, mediaElement: element });
    }
  };

  // update audio output on state change in a listener
  updateAudioOutput = (id: string) => {
    for (const element of Object.values(this.audioElements)) {
      this.setAudioElementOutput(element, id).catch(() => {
        // do nothing
      });
    }
  };

  attachVideo = async (mid: SubscriberMid, ref: MutableRefObject<HTMLVideoElement | null>) => {
    const feedId = this.feedIdByMid[mid];
    const stream = this.media.subscriberStream(feedId, mid);

    if (!(feedId && stream)) {
      logger
        .remote({ system: true, capture: 'streaming' })
        .warn(
          'Got an anomalous behaviour while attaching a video element. No feed or an active stream?'
        );
      return;
    }

    if (!ref.current) {
      return;
    }

    logger
      .remote({ system: true, tier: 1 })
      .info(`Adding media to UI, feed=${feedId}, mid=${mid}, type=video`);

    try {
      const isFF = Janus.webRTCAdapter.browserDetails.browser === 'firefox';

      const needFFHack = isFF && this.receiver instanceof StreamingReceiver;

      const track = stream.mediaStream.getVideoTracks()?.[0];

      if (needFFHack && track?.muted) {
        track.onunmute = () => {
          ref.current!.onloadedmetadata = null;
          ref.current!.srcObject = null;
          ref.current!.srcObject = stream.mediaStream;

          ref.current!.play();
        };
      } else {
        ref.current.onloadedmetadata = null;
        ref.current.srcObject = null;
        ref.current.srcObject = stream.mediaStream;
      }

      ref.current.onloadeddata = () => {
        ref.current?.play();
      };

      if (isFF) {
        // use shitty approach for FF because it has to be special;
        // I'll gladly drop this one happy day.
        ref.current?.addEventListener(
          'timeupdate',
          throttle(
            () => {
              if (ref.current) {
                store.dispatch(
                  remoteVideoRatioChanged({
                    feedId,
                    ratio: ref.current.videoHeight / ref.current.videoWidth,
                  })
                );
              }
            },
            1000,
            { leading: true }
          )
        );
      } else {
        ref.current.onloadedmetadata = () => {
          if (ref.current) {
            store.dispatch(
              remoteVideoRatioChanged({
                feedId,
                ratio: ref.current.videoHeight / ref.current.videoWidth,
              })
            );
          }
        };
      }

      // bypass Save-Data setting since the "loadeddata" event will not fire in mobile devices with this setting enabled
      if ('connection' in navigator) {
        // @ts-ignore
        if (navigator.connection.saveData === true) {
          await ref.current.play();
        }
      }
    } catch (error) {
      if (error instanceof DOMException && error.message.includes('was interrupted')) {
        // do nothing;
      } else {
        Sentry.captureException(error);
        logger.remote().error('An error occurred while attaching a video element', error);
      }
    }

    const feed = this.receiver?.plugin;

    if (feed) {
      this.streamsStats[mid] = new MediaStreamStats({
        feed,
        feedId,
        mid,
        mediaElement: ref.current,
      });
    }
  };

  detachFromElement = (mid: SubscriberMid, ref?: MutableRefObject<HTMLMediaElement | null>) => {
    const feedId = this.feedIdByMid[mid];

    if (!feedId) {
      return;
    }

    // TODO: see if this creates a new record and somehow messes up the collection in the "EDGE CASE"
    const media = this.media.subscriberStream(feedId, mid);

    const isMediaBroadcasting = RTCClient.broadcastingByUserId[RTCClient.userIdByFeedId[feedId]];

    if (isMediaBroadcasting) {
      Sentry.addBreadcrumb({
        type: 'streaming',
        message: 'Media removed from UI is considered to be enabled',
        data: {
          feedId,
          mid,
        },
      });
    }

    logger
      .remote({ system: true, tier: 1 })
      .info(
        `Removing media from UI, feed=${feedId}, mid=${mid}, type=${
          media?.kind
        }. Media is considered to be ${isMediaBroadcasting ? 'enabled' : 'disabled'}`
      );

    if (ref?.current) {
      ref.current.pause();
      ref.current.onloadeddata = null;
    }

    if (this.streamsStats[mid]) {
      delete this.streamsStats[mid];
    }
  };

  unloadFeedAudio = (userId: UserId) => {
    const feedIdByUserId = invert(RTCClient.userIdByFeedId);
    const feedId = feedIdByUserId[userId];

    if (feedId) {
      this.media.getFeedStreams(feedId).forEachStream((stream) => {
        if (stream.kind === 'audio' && stream.subscriberMid) {
          this.unloadAudio(stream.subscriberMid);
        }
      });
    }
  };

  private unloadAudio = (mid: SubscriberMid) => {
    if (this.audioElements[mid] instanceof UnlockedAudio) {
      AudioPool.unloadElement(this.audioElements[mid] as UnlockedAudio);
      delete this.audioElements[mid];
    }
  };

  handleSubscriptionsUpdate = (
    feed: ControlledReceivingHandle,
    streams: JanusJS.StreamUpdate[]
  ) => {
    const feedIdByMid: Record<SubscriberMid, FeedId> = {};

    streams.forEach((stream) => {
      feedIdByMid[stream.mid] = stream.feed_id;

      if (feedIdByMid[stream.mid] === undefined && this.feedIdByMid[stream.mid]) {
        feedIdByMid[stream.mid] = this.feedIdByMid[stream.mid];
      }

      if (stream.active) {
        // update pub<->sub mid map in list of what's available;
        this.media.setSubscriberMid(stream.feed_id, stream.feed_mid, stream.mid);
      }
    });

    // See if it slows down UI too much or is acceptable;
    this.feedIdByMid = feedIdByMid;
    this.updateRedux();
  };

  addAvailableStreams = (feedId: FeedId, streams: FeedStreamInfo[]) => {
    const localMedia = feedId === RTCClient.publishingFeed.handle?.feedId;
    const localScreenshare = feedId === RTCClient.screensharingFeed.handle?.feedId;

    if ((localMedia || localScreenshare) && this.receiver instanceof StreamingReceiver) {
      this.receiver.stopLocalStreams(streams.map((stream) => ({ mid: stream.mid, send: false })));
    }

    streams?.forEach((stream) => {
      const streamMedia = this.media.stream(feedId, stream.mid);

      streamMedia.setKind(stream.type);
    });
  };

  // internals

  attachToConnection = (connection: JanusConnection) => {
    const videoroomHandle = uuidv4();

    this.receiver?.attachPlugin(videoroomHandle, connection);
  };

  private removeOriginalTrack = (feed: ControlledReceivingHandle, trackId: string) => {
    const { remoteStream } = feed.janusPlugin.webrtcStuff;

    if (remoteStream) {
      const track = remoteStream.getTracks().find((t) => t.id === trackId);
      if (track && this.receiver instanceof VideoroomReceiver) {
        Janus.log('Removing remote track', track);
        remoteStream.removeTrack(track);
      }
    }
  };

  private prepareE2EESubscription = (connectionHandle: string, streams: SubscribeStreamList) => {
    const filteredStreams: SubscribeStreamList = [];

    streams?.forEach((stream) => {
      const { feed } = stream;
      const userId = RTCClient.userIdByFeedId[feed] || RTCClient.userIdByScreensharingFeedId[feed];

      const keys = E2EEManager.getReceiver(userId);
      const keysExchanged = Boolean(keys?.sharedSecret);

      if (keysExchanged) {
        filteredStreams.push(stream);
      } else {
        logger
          .remote()
          .debug(
            `E2E keys have not been exchanged yet with userId=${userId} of feed=${stream.feed}, ${
              stream.mid && `mid=${stream.mid}`
            }. Enqueue this subscription until keys are exchanged.`
          );

        this.e2eeQueue[userId] ??= {
          connectionHandle,
          streams: [],
        };
        this.e2eeQueue[userId].streams.push(stream);
      }
    });

    return filteredStreams;
  };

  prepareSubscription = (connectionHandle: string, streams: SubscribeStreamList) => {
    Sentry.addBreadcrumb({
      category: 'streaming',
      message: 'prepareSubscription call',
      data: { streams },
    });

    if (E2EEManager.e2eeEnabled) {
      streams = this.prepareE2EESubscription(connectionHandle, streams);
    }

    const validStreams: { feed: string; mid?: string | undefined }[] = [];
    const invalidStreams: {
      feed: string;
      mid?: string | undefined;
      state: StreamSubscriptionState;
    }[] = [];

    streams?.forEach((stream) => {
      if (stream.mid) {
        const streamInfo = this.media.stream(stream.feed, stream.mid);

        if (streamInfo.canSubscribe) {
          validStreams.push(stream);
        } else {
          invalidStreams.push({ ...stream, state: streamInfo.subscriptionState });
        }
      } else {
        validStreams.push(stream);
      }
    });

    logger
      .remote({ tier: 1 })
      .warn(
        `Requested streams:`,
        streams,
        'Valid streams:',
        validStreams,
        'Invalid streams:',
        invalidStreams
      );

    return validStreams;
  };

  requestSubscription = (connectionHandle: string, requestedStreams: SubscribeStreamList) => {
    const streams = this.prepareSubscription(connectionHandle, requestedStreams);

    logger
      .remote({ tier: 1 })
      .info(
        `Subscribing to ${streams.length} streams: `,
        streams,
        'requested streams:',
        requestedStreams
      );

    if (streams.length) {
      this.receiver?.subscribe(connectionHandle, streams);
    }
  };

  unsubscribe = (feedId: FeedId) => {
    if (!this.receiver?.plugin) {
      return;
    }

    this.media.deleteFeed(feedId);
    delete this.feedIdByMid[feedId];
    RTCClient.disconnectFeed(feedId);

    this.receiver.unsubscribe(feedId);

    logger.remote().info(`Unsubscribed from remote feed ${feedId}`);

    this.updateRedux();
  };

  addStreamTrack = (sourceHandle: string, track: MediaStreamTrack, mid: SubscriberMid) => {
    const feedId = this.feedIdByMid[mid];

    logger
      .remote({ tier: 1 })
      .info(`Received remote track from Janus, mid=${mid}, feedId=${feedId}`);

    if (feedId === undefined) {
      // ignore bs track;
      return;
    }

    const streamMedia = this.media.subscriberStream(feedId, mid);

    if (!streamMedia) {
      return;
    }

    // covering extremely rare case when mid was freed and someone else was put on it, we need to remove stale record from collection;
    const oldMedia = Object.entries(this.media.streams).find(
      ([mediaFeedId, media]) =>
        mediaFeedId !== feedId &&
        Object.values(media.streams).find((stream) => stream.subscriberMid === mid)
    );

    if (oldMedia) {
      const matchingStream = Object.values(oldMedia[1].streams).find(
        (stream) => stream.subscriberMid === mid
      );

      if (matchingStream) {
        const message = `${matchingStream?.kind} mid ${mid} was reassigned from feed=${oldMedia[0]} to feed=${feedId}, removing old entry`;
        logger.remote({ capture: 'streaming' }).warn(message);

        Sentry.captureException(message, {
          contexts: {
            streaming: {
              feedId,
              collectionMid: this.media.publisherMidBySubscriberMid[mid],
              mid,
            },
          },
        });

        this.media.deleteStream(oldMedia[0], matchingStream.publisherMid);
      }
    }

    let trackToRemove: MediaStreamTrack | null = null;

    if (streamMedia.hasTrack) {
      if (RTCClient.receiveMode === 'videoroom') {
        return;
      }

      logger
        .remote({ capture: 'streaming' })
        .info(
          `Replacing controlled track with mid: ${mid}, in feed ${feedId}, with a new instance`,
          {
            track,
            feedId,
            mid,
          }
        );

      trackToRemove = streamMedia.getTrack();
    }

    logger
      .remote({ tier: 1 })
      .info(`Adding track with mid: ${mid}, in feed ${feedId}`, { track, feedId, mid });

    this.media.setKind(feedId, streamMedia.publisherMid, track.kind as unknown as StreamKind);

    streamMedia.addTrack(track);

    if (trackToRemove) {
      streamMedia.removeTrack(trackToRemove);
    }

    streamMedia.setReceivingState(ReceivingFeedStreamSendingState.on);

    streamMedia.setSubscriptionState(StreamSubscriptionState.connected);

    this.updateRedux();
  };

  removeStreamTrack = (feedId: FeedId, publisherMid: PublisherMid) => {
    const stream = this.media.stream(feedId, publisherMid);
    const mid = stream.subscriberMid;

    if (!(feedId && mid)) {
      return;
    }

    const message = `Removing track with mid: ${mid}, in feed ${feedId}`;
    Sentry.addBreadcrumb({
      category: 'streaming',
      message,
      data: { feedId, mid },
    });

    logger.remote({ tier: 1 }).info(`Removing track with mid: ${mid}, in feed ${feedId}`);
    const feed = this.receiver?.plugin;

    if (!feed) {
      return;
    }

    this.receiver?.unsubscribe(feedId, mid);

    const tracks = stream?.mediaStream.getTracks();

    // Cleanup controlled track copies. Janus will handle original tracks
    tracks?.forEach((track) => {
      track.stop();
    });

    this.removeOriginalTrack(feed, stream?.originalTrackId);

    this.media.deleteStream(feedId, publisherMid);

    this.updateRedux();
    if (stream.kind === 'audio') {
      this.unloadFeedAudio(mid);
    }
  };

  cleanupFeed = () => {
    this.media.forEachFeed((feed) => {
      feed.forEachStream((stream) => {
        if (stream.subscriberMid && this.audioElements[stream.subscriberMid]) {
          this.unloadAudio(stream.subscriberMid);
        }

        const tracks = stream.mediaStream.getTracks();

        if (tracks.length) {
          tracks.forEach((t) => {
            t.stop();
          });
        }
      });
    });

    this.media = new MediaList();

    this.feedIdByMid = {};

    this.streamsStats = {};
    this.e2eeQueue = {};

    store.dispatch(remoteFeedDisconnected());

    logger.debug('Receiving Feed: cleaned up connection');
  };

  cleanupConnection = (detach = false) => {
    logger.debug('Receiving Feed: cleaning up connection');

    const feed = this.receiver?.plugin;

    if (feed && RTCClient.isMediaServerError) {
      this.cleanupFeed();
      return;
    }

    if (!feed) {
      return;
    }

    if (feed.connectionState === VideoroomConnectionState.disconnecting) {
      return;
    }

    feed.setConnectionState(VideoroomConnectionState.disconnecting);
    if (detach) {
      feed.janusPlugin.detach();
    } else {
      feed.janusPlugin.hangup();
    }
  };

  cleanupSubscription = async (sourceId: FeedId) => {
    this.receiver?.unsubscribe(sourceId);
  };

  resetUserStreams = async (userId: UserId) => {
    const feedIdByUserId = invert(RTCClient.userIdByFeedId);
    const screensharingFeedIdByUserId = invert(RTCClient.userIdByScreensharingFeedId);

    const feedId = feedIdByUserId[userId];
    const screensharingFeedId = screensharingFeedIdByUserId[userId];

    if (feedId) {
      this.media.deleteFeed(feedId);
    }

    if (screensharingFeedId) {
      this.media.deleteFeed(screensharingFeedId);
    }
  };

  // redux layer
  private get uiState(): RemoteFeedUIState {
    const streamsByFeedId: RemoteStreamsByFeedId = {};

    this.media.forEachSubscribedFeed((list, feedId) => {
      streamsByFeedId[feedId] = [];

      // What's a memory leak anyway?
      const dedupedStreams: Record<string, RemoteFeedStream> = {};

      list.forEachStream((stream) => {
        if (dedupedStreams[stream.kind]) {
          logger
            .remote({ system: true, capture: 'streaming' })
            .error(
              `Caught duplicate ${stream.kind} stream in ${feedId}, dupe mid ${
                dedupedStreams[stream.kind].mid
              }`
            );
        }

        if (stream.subscriberMid) {
          const simulcast = this.media.subscriberStream(feedId, stream.subscriberMid)?.simulcast;

          dedupedStreams[stream.kind] = {
            kind: stream.kind,
            mid: stream.subscriberMid,
            substream: simulcast?.substream,
            temporal: simulcast?.temporal,
          };
        }
      });

      streamsByFeedId[feedId] = Object.values(dedupedStreams);
    });

    return {
      feedIdByMid: { ...this.feedIdByMid },
      streamsByFeedId,
    };
  }

  updateRedux = () => {
    clearTimeout(this.updateTimer);
    this.updateTimer = window.setTimeout(() => {
      const state = this.uiState;
      store.dispatch(remoteFeedUpdated(state));
    }, 16);
  };

  getBaseSimulcastConfig = (newUsers: number) => {
    const deviceType = selectDeviceType(store.getState());
    const tileWidth = selectTileWidth(store.getState());

    if (deviceType === 'mobile' || tileWidth <= 290) {
      return [0, 1];
    }

    if (newUsers <= 4) {
      return [2, 1];
    }

    return [0, 0];
  };

  getSpeakerSimulcastConfig = () => {
    const deviceType = selectDeviceType(store.getState());
    const tileWidth = selectTileWidth(store.getState());

    if (tileWidth <= 290) {
      return [
        [0, 1],
        [0, 1],
        [0, 1],
      ];
    }

    if (deviceType === 'mobile') {
      return [
        [1, 1],
        [0, 1],
        [0, 1],
      ];
    }

    return [
      [2, 1],
      [1, 1],
      [1, 0],
    ];
  };

  updateSimulcastConfig = async () => {
    const state = store.getState();

    const speakers = selectHistoricalSpeakers(state);
    const streams = selectOrderedStreams(state);
    const maximizedStream = selectMaximizedStream(state);

    const feedIdByUserId = invert(RTCClient.userIdByFeedId);
    const feedsWithVideo = this.media.feedsWithVideo.length;

    const defaultConfig = this.getBaseSimulcastConfig(feedsWithVideo);
    const speakerConfig = this.getSpeakerSimulcastConfig();

    const specialConfig: Record<UserId, number[]> = {};

    if (feedsWithVideo > 4) {
      speakers.slice(0, 2).forEach((speaker, idx) => {
        specialConfig[speaker.userId] = speakerConfig[idx];
      });
    }

    if (maximizedStream) {
      specialConfig[maximizedStream.userId] = [2, 1];
    }

    const configuredStreams: ConfigureSimulcastTemplate = [];

    for (const stream of streams) {
      const feedId = feedIdByUserId[stream.userId];
      const streamDetails = this.media.getFeedStreams(feedId);

      streamDetails.forEachStream((source) => {
        if (source.subscribed && source.subscriberMid) {
          const [substream, temporal] = specialConfig[stream.userId]
            ? specialConfig[stream.userId]
            : defaultConfig;

          if (source.simulcast.temporal !== temporal || source.simulcast.substream !== substream) {
            source.setSimulcastValues(substream, temporal);
            configuredStreams.push({ mid: source.subscriberMid, substream, temporal });
          }
        }
      });
    }

    if (configuredStreams.length) {
      if (this.receiver?.plugin?.janusPlugin) {
        await sendMessage(
          this.receiver.plugin.janusPlugin,
          updateSimulcastTemplate(configuredStreams)
        );
      }

      this.updateRedux();
    }
  };

  resetSimulcastSettings = () => {
    this.media.forEachFeed((list) => {
      list.forEachStream((stream) => {
        stream.setSimulcastValues(undefined, undefined);
      });
    });
  };

  updateSimulcastValue = (mid: SubscriberMid, substream?: number, temporal?: number) => {
    const feedId = this.feedIdByMid[mid];
    const streamMedia = this.media.subscriberStream(feedId, mid);

    try {
      streamMedia?.setSimulcastValues(substream, temporal);
    } catch (error) {
      const message = `Couldn't update simulcast in ${feedId} for mid ${mid}, error:`;
      Sentry.captureMessage(message, 'warning');
      logger.remote({ tier: 1 }).error(message, error);
    }

    this.updateRedux();
  };

  subscribeToE2eeUser = async (userId: UserId) => {
    const pendingSubscription = this.e2eeQueue[userId];

    if (pendingSubscription) {
      logger
        .remote({ tier: 1 })
        .debug(`Releasing pending E2EE subscription for the userId=${userId}`);

      await this.requestSubscription(
        pendingSubscription.connectionHandle,
        pendingSubscription.streams
      );
      delete this.e2eeQueue[userId];
    }
  };

  removeE2eePendingSubscription = ({ userId, mid, feedId }: E2EEQueueUpdateOptions) => {
    const pendingSubscription = this.e2eeQueue[userId];
    if (pendingSubscription) {
      logger
        .remote({ tier: 1 })
        .debug(
          `Removing pending E2EE subscription for userId=${userId} with feedId=${feedId} on mid=${mid}`
        );

      pendingSubscription.streams = pendingSubscription.streams.filter(
        (stream) => !(stream.feed === feedId && stream.mid === mid)
      );
    }
  };
}

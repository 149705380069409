import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  normalizeContentLibrary,
  normalizeFile,
  normalizeFolder,
} from 'features/content-library/utils/normalizeContentLibrary';
import {
  signalingContentLibraryFileDeleted,
  signalingContentLibraryFileRenamed,
  signalingContentLibraryFileUploadAccepted,
  signalingContentLibraryFileUploaded,
  signalingContentLibraryFolderCreated,
  signalingContentLibraryFolderDeleted,
  signalingContentLibraryFolderRenamed,
  signalingLibraryReceived,
} from 'features/content-library/actions';
import {
  ContentLibraryFile,
  ContentLibraryFileUploadMeta,
  ContentLibraryFolder,
  ContentLibraryItem,
  ContentLibraryOpenedFile,
  ContentLibraryState,
  DeleteContentLibraryItemPayload,
} from './types';

export const initialState: ContentLibraryState = {
  openedFile: null,
  libraryId: '',
  activeFolderId: 'root',
  folders: {},
  files: {},
  uploadMeta: {},
};

export const contentLibrary = createSlice({
  name: 'contentLibrary',
  initialState,
  reducers: {
    folderOpened(state, action: PayloadAction<string>) {
      state.activeFolderId = action.payload;
    },
    fileUploadProgressChanged(state, action: PayloadAction<{ id: string; progress: number }>) {
      const meta = state.uploadMeta[action.payload.id];
      if (meta) {
        meta.progress = action.payload.progress;
      }
    },
    fileUploadCancelled(state, action: PayloadAction<DeleteContentLibraryItemPayload>) {
      const meta = state.uploadMeta[action.payload.id];
      const parentFolderId = meta.folderId;

      // Remove the fileId from the parent folder's childUploadIds
      const parentFolder = state.folders[parentFolderId];
      if (parentFolder) {
        parentFolder.childUploadIds = parentFolder.childUploadIds.filter(
          (id) => id !== action.payload.id
        );
      }

      delete state.uploadMeta[action.payload.id];
    },
    fileOpened(state, action: PayloadAction<ContentLibraryOpenedFile>) {
      state.openedFile = action.payload;
    },
    fileClosed(state) {
      state.openedFile = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signalingLibraryReceived, (state, action) => {
        if (action.payload.id === null) {
          return initialState;
        }

        const { folders, files } = normalizeContentLibrary(action.payload);
        state.folders = folders;
        state.files = files;
        state.libraryId = action.payload.id;
      })
      .addCase(signalingContentLibraryFolderCreated, (state, action) => {
        const folder = normalizeFolder(action.payload);

        state.folders[folder.id] = folder;
        if (state.folders[folder.parentId]) {
          state.folders[folder.parentId].childFolderIds.push(folder.id);
        }
      })
      .addCase(signalingContentLibraryFolderRenamed, (state, action) => {
        const folder = state.folders[action.payload.id];
        if (folder) {
          folder.name = action.payload.name;
        }
      })
      .addCase(signalingContentLibraryFileRenamed, (state, action) => {
        const file = state.files[action.payload.id];
        if (file) {
          file.name = action.payload.name;
        }
      })
      .addCase(signalingContentLibraryFileDeleted, (state, action) => {
        const fileId = action.payload.id;
        const file = state.files[fileId];

        if (file) {
          // Remove the file from its parent folder's childFileIds
          const parentFolder = state.folders[file.folderId];
          if (parentFolder) {
            parentFolder.childFileIds = parentFolder.childFileIds.filter((id) => id !== fileId);
          }

          delete state.files[fileId];
        }
      })
      .addCase(signalingContentLibraryFolderDeleted, (state, action) => {
        const { id: deletedFolderId } = action.payload;
        const deletedFolder = state.folders[deletedFolderId];

        if (deletedFolder) {
          // Remove the folder from its parent's childFolderIds
          const parentFolder = state.folders[deletedFolder.parentId];
          if (parentFolder) {
            parentFolder.childFolderIds = parentFolder.childFolderIds.filter(
              (id) => id !== deletedFolderId
            );
          }

          delete state.folders[deletedFolderId];

          // If the deleted folder was the active folder, set the parent folder as the new active folder
          if (deletedFolderId === state.activeFolderId) {
            state.activeFolderId = deletedFolder.parentId || 'root';
          }
        }
      })
      .addCase(signalingContentLibraryFileUploadAccepted, (state, action) => {
        const { id, name, folderId } = action.payload;
        const parentFolder = folderId || 'root';

        state.uploadMeta[action.payload.id] = {
          id,
          name,
          status: 'uploading',
          progress: 0,
          folderId: parentFolder,
        };
        if (state.folders[parentFolder]) {
          state.folders[parentFolder].childUploadIds.push(id);
        }
      })
      .addCase(signalingContentLibraryFileUploaded, (state, action) => {
        delete state.uploadMeta[action.payload.id];

        const file = normalizeFile(action.payload);

        state.files[file.id] = file;
        if (state.folders[file.folderId]) {
          state.folders[file.folderId].childFileIds.push(file.id);
        }
      });
  },
});

export const {
  folderOpened,
  fileUploadProgressChanged,
  fileUploadCancelled,
  fileOpened,
  fileClosed,
} = contentLibrary.actions;

export default contentLibrary.reducer;

export const selectContentLibraryId = (state: RootState) => state.contentLibrary.libraryId;

export const selectFiles = (state: RootState) => state.contentLibrary.files;
export const selectFolders = (state: RootState) => state.contentLibrary.folders;

export const selectFolder = (state: RootState, id: string): ContentLibraryFolder | undefined =>
  state.contentLibrary.folders[id];

export const selectFile = (state: RootState, id: string): ContentLibraryFile | undefined =>
  state.contentLibrary.files[id];

export const selectActiveFolderId = (state: RootState) => state.contentLibrary.activeFolderId;

const selectUploadMeta = (state: RootState) => state.contentLibrary.uploadMeta;

export const selectActiveFolder = createSelector(
  [selectActiveFolderId, selectFolders],
  (activeFolderId, folders) => folders[activeFolderId]
);

export const selectContentLibraryHasContent = createSelector(
  [(state) => selectFolder(state, 'root')],
  (rootFolder) => {
    if (!rootFolder) {
      return false;
    }

    // @TODO make it more intuitive?
    return (
      rootFolder.childFolderIds.length > 0 ||
      rootFolder.childFileIds.length > 0 ||
      rootFolder.childUploadIds.length > 0
    );
  }
);

export const selectContentLibraryContent = createSelector(
  [selectActiveFolderId, selectFolders, selectFiles, selectUploadMeta],
  (activeFolderId, folders, files, uploadMeta) => {
    const activeFolder = folders[activeFolderId];
    if (!activeFolder) {
      return [];
    }

    const items: ContentLibraryItem[] = [];

    for (const childFolderId of activeFolder.childFolderIds) {
      const folder = folders[childFolderId];
      if (folder) {
        items.push({
          id: folder.id,
          name: folder.name,
          isFolder: true,
          type: null,
        });
      }
    }

    for (const childFileId of activeFolder.childFileIds) {
      const file = files[childFileId];
      if (file) {
        items.push({
          id: file.id,
          name: file.name,
          isFolder: false,
          type: file.type,
        });
      }
    }

    for (const childUploadId of activeFolder.childUploadIds) {
      const meta = uploadMeta[childUploadId];
      if (meta) {
        items.push({
          id: meta.id,
          name: meta.name,
          isFolder: false,
          type: null,
        });
      }
    }

    return items;
  }
);

export const selectContentLibraryFileUploadMeta = (
  state: RootState,
  id: string
): ContentLibraryFileUploadMeta | undefined => state.contentLibrary.uploadMeta[id];

export const selectContentLibraryOpenedFile = (state: RootState) => state.contentLibrary.openedFile;

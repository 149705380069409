import { put, select } from 'redux-saga/effects';
import {
  fileOpened,
  selectContentLibraryOpenedFile,
} from 'features/content-library/contentLibrarySlice';
import { signalingContentLibraryFileOpened } from 'features/content-library/actions';
import { ContentLibraryFile } from 'features/content-library/types';

export function* onContentLibraryFileOpenedSaga(
  action: ReturnType<typeof signalingContentLibraryFileOpened>
) {
  const openedFile: ContentLibraryFile | undefined = yield select(selectContentLibraryOpenedFile);

  // skip already opened file
  if (action.payload.data.id === openedFile?.id) {
    return;
  }

  yield put(fileOpened(action.payload.data));
}

import { SingleImageFile } from 'features/content-library/components/file-panel/SingleImageFile';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectContentLibraryOpenedFile } from 'features/content-library/contentLibrarySlice';

// import 'swiper/css';
// import 'swiper/css/free-mode';
// import 'swiper/css/pagination';
// import 'swiper/css/virtual';

export const FilePanel = () =>
  // { size }: { size: { width?: number; height?: number } }
  {
    const openedFile = useAppSelector(selectContentLibraryOpenedFile);
    if (!openedFile) {
      return null;
    }

    // if ('pages' in file) {
    //   return <GalleryFile file={file} size={size} />;
    // }

    return <SingleImageFile file={openedFile} />;
  };

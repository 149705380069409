import { Slide, SlideImg } from 'features/content-library/components/file-panel/styled';
import { GridPanelContainer } from 'features/layout/GridPanel/GridPanelContainer';
import { StreamBadge } from 'features/streaming/components/stream-display/StreamBadge';
import { ContentLibraryOpenedFile } from 'features/content-library/types';

export type SingleImageFileProps = {
  file: ContentLibraryOpenedFile;
};

export const SingleImageFile = ({ file }: SingleImageFileProps) => (
  <GridPanelContainer>
    <Slide sx={{ flexGrow: 1 }}>
      <StreamBadge label={file.name} />
      <SlideImg src={file.url} />
    </Slide>
  </GridPanelContainer>
);
